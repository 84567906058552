.DutyTable-table {
  background-color: #4c4c4c9a;
}

.DutyTable-tr:nth-child(odd) {
  background-color: #cdcdcd;
}

.DutyTable-tr:nth-child(even) {
  background-color: #b6b6b6;
}

.DutyTable-td,
.DutyTable-th,
.DutyTable-tr {
  padding: 12px 40px;
  border-radius: 2px;
  min-width: 24px;
}

.DutyTable-td {
  text-align: center;
}

.DutyTable-dark {
  background-color: #7d7d7d;
}
