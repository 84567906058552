.LeaveTable-tr:nth-child(odd) {
  background-color: #cdcdcd;
}

.LeaveTable-tr:nth-child(even) {
  background-color: #b6b6b6;
}

.LeaveTable-td,
.LeaveTable-th,
.LeaveTable-tr {
  padding: 6px;
  border-radius: 2px;
  min-width: 24px;
}

.LeaveTable-td {
  text-align: center;
}

.LeaveTable-name {
  text-align: left;
}

.LeaveTable-dark {
  background-color: #979797;
}

.LeaveTable-pointer:not(.LeaveTable-dark):hover {
  background: #cbbf42ba;
  cursor: pointer;
}

.LeaveTable-dayIsOff {
  background: linear-gradient(125deg, #7456b1, #c7b041);
}

.LeaveTable-updatable {
  background-color: #8686869a;
}

.LeaveTable-unupdatable {
  background-color: #4c4c4c9a;
}
